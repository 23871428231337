import opportunitiesApi, { OpportunityRequest } from "api/opportunities"
import tasksApi, { TaskRequest } from "api/tasks"
import { Opportunity } from "models/opportunity"
import { Task } from "models/task"
import usePromise from "pmsa-polaris/hooks/usePromise"
import useQueryString from "pmsa-polaris/hooks/useQueryString"
import React, { useCallback, useContext, useEffect, useState } from "react"

const DEFAULT_CONTEXT: {
  opportunityList: {
    loading: boolean
    error: any
    data: Readonly<Opportunity>[] | undefined
  }
  taskList: {
    loading: boolean
    error: any
    data: Readonly<Task>[] | undefined
  }
  getOpportunityList: () => Promise<Readonly<Opportunity>[]>
  getOpportunity: (id: string) => Promise<Readonly<Opportunity>>
  updateOpportunity: (id: string, params: OpportunityRequest) => Promise<Readonly<Opportunity>>
  createOpportunity: (opportunity: OpportunityRequest) => Promise<Readonly<Opportunity>>
  getTaskList: () => Promise<Readonly<Task>[]>
  getTask: (id: string) => Promise<Readonly<Task>>
  updateTask: (id: string, params: TaskRequest) => Promise<Readonly<Task>>
  createTask: (params: TaskRequest) => Promise<Readonly<Task>>
} = {
  opportunityList: {
    loading: false,
    error: null,
    data: undefined,
  },
  taskList: {
    loading: false,
    error: null,
    data: undefined,
  },
  getOpportunityList: () => Promise.resolve([]),
  getOpportunity: (id: string) => Promise.resolve({} as Readonly<Opportunity>),
  updateOpportunity: () => Promise.resolve({} as Readonly<Opportunity>),
  createOpportunity: (opportunity: OpportunityRequest) => Promise.resolve({} as Readonly<Opportunity>),

  getTaskList: () => Promise.resolve([]),
  getTask: (id: string) => Promise.resolve({} as Readonly<Task>),
  updateTask: () => Promise.resolve({} as Readonly<Task>),
  createTask: (task: TaskRequest) => Promise.resolve({} as Readonly<Task>),
}

const OpportunityTaskContext = React.createContext(DEFAULT_CONTEXT)

const OpportunityContextProvider: React.FC = ({ children }) => {
  const queryParams = useQueryString()
  const { alias } = queryParams

  // opp & task objects
  const [oppList, setOppList] = useState<Opportunity[] | undefined>(undefined)
  const [taskList, setTaskList] = useState<Task[] | undefined>(undefined)

  // opportunity API calls
  const [getOpportunityListResponse, getOpportunityList] = usePromise(opportunitiesApi.getAll)
  const [getOpportunityResponse, getOpportunity] = usePromise(opportunitiesApi.get)
  const [updateOpportunityResponse, updateOpportunity] = usePromise(opportunitiesApi.update)
  const [createOpportunityResponse, createOpportunity] = usePromise(opportunitiesApi.create)

  // task API calls
  const [getTaskListResponse, getTaskList] = usePromise(tasksApi.getAll)
  const [getTaskResponse, getTask] = usePromise(tasksApi.get)
  const [updateTaskResponse, updateTask] = usePromise(tasksApi.update)
  const [createTaskResponse, createTask] = usePromise(tasksApi.create)

  // ensure that opportunity list is loaded
  useEffect(() => {
    try {
      if (getOpportunityListResponse.data) setOppList(getOpportunityListResponse.data)
      else if (!getOpportunityListResponse.loading && !getOpportunityListResponse.error) getOpportunityList(true, alias)
    } catch (error) {
      console.log(error)
    }
  }, [getOpportunityListResponse, getOpportunityList, alias])

  // ensure that task list is loaded
  useEffect(() => {
    try {
      if (getTaskListResponse.data) setTaskList(getTaskListResponse.data)
      else if (!getTaskListResponse.loading && !getTaskListResponse.error) getTaskList(alias)
    } catch (error) {
      console.log(error)
    }
  }, [getTaskListResponse, getTaskList, alias])

  // after getOpportunity update item in opportunity list
  useEffect(() => {
    if (getOpportunityResponse.data && !getOpportunityResponse.loading) {
      const id = getOpportunityResponse.data.id
      setOppList((prev) => {
        const index = prev?.findIndex((opp) => opp.id == id)
        if (prev && index != undefined && index >= 0) prev[index] = getOpportunityResponse.data as Opportunity
        return prev
      })
    }
  }, [getOpportunityResponse])

  // after getTask update item in task list
  useEffect(() => {
    if (getTaskResponse.data) {
      const id = getTaskResponse.data.id

      if (getTaskResponse.data.relatedType === "opportunity" && getTaskResponse.data.relatedId) getOpportunity(getTaskResponse.data.relatedId)

      setTaskList((prev) => {
        const index = prev?.findIndex((task) => task.id === id)
        if (prev && index != undefined && index >= 0) prev[index] = getTaskResponse.data as Task
        return prev
      })
    }
  }, [getOpportunity, getTaskResponse.data])

  // return context provider
  return (
    <OpportunityTaskContext.Provider
      value={{
        opportunityList: {
          loading:
            getOpportunityListResponse.loading || getOpportunityResponse.loading || createOpportunityResponse.loading || updateOpportunityResponse.loading,
          error: getOpportunityListResponse.error || getOpportunityResponse.error || createOpportunityResponse.error || updateOpportunityResponse.error,
          data: oppList,
        },
        taskList: {
          loading: getTaskListResponse.loading || getTaskResponse.loading || createTaskResponse.loading || updateTaskResponse.loading,
          error: getTaskListResponse.error || getTaskResponse.error || createTaskResponse.error || updateTaskResponse.error,
          data: taskList,
        },

        getOpportunityList: useCallback(() => getOpportunityList(true, alias), [alias, getOpportunityList]),
        getOpportunity: getOpportunity,
        updateOpportunity: updateOpportunity,
        createOpportunity: createOpportunity,
        getTaskList: useCallback(() => getTaskList(alias), [alias, getTaskList]),
        getTask: getTask,
        updateTask: updateTask,
        createTask: createTask,
      }}
    >
      {children}
    </OpportunityTaskContext.Provider>
  )
}

export const useOpportunityTaskContext = () => useContext(OpportunityTaskContext)

export default OpportunityContextProvider
