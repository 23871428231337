import { ProfileData } from "models/profile"
import request from "pmsa-polaris/api/request"

export type ProfileRequest = ProfileData

export type ProfileResponse = Readonly<ProfileData>

const profilesApi = {
  get: async () => request.get<ProfileResponse>("/profile"),

  update: (params: ProfileData) => request.put<ProfileResponse>("/profile", params),
}

export default profilesApi
