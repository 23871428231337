/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Input } from "@amzn/awsui-components-react"
import Select, { SelectProps } from "@amzn/awsui-components-react/polaris/select"
import accountsApi from "api/accounts"
import campaignsApi from "api/campaigns"
import opportunitiesApi from "api/opportunities"
//import accountsApi, { AccountResponse } from "api/accounts"----> [ttsenkov] task to account
import { useOpportunityTaskContext } from "context/OpportunityContext"
import { debounce } from "lodash"
import usePromise from "pmsa-polaris/hooks/usePromise"
import { useCallback, useEffect, useState } from "react"

type Props = {
  onSelected?: (type: string, relatedObject: any) => void
  initialOption?: SelectProps.Option
  initialType?: string
}

const RelatedToPicker: React.FC<Props> = ({ onSelected, initialOption, initialType = "" }) => {
  const [searchType, setSearchType] = useState(initialType || "myOpportunities")
  const [selectedOption, setSelectedOption] = useState<SelectProps.Option | null>(initialOption ? initialOption : null)
  const [searchAlias, setSearchAlias] = useState("")
  const [searchPartner, setSearchPartner] = useState("")
  const [searchCustomer, setSearchCustomer] = useState("")

  useEffect(() => {
    setSelectedOption(initialOption ? initialOption : null)
  }, [initialOption])

  // api promises
  const [searchCampaignsRes, searchCampaigns] = usePromise((searchText: string) => campaignsApi.search(searchText))
  const [searchPartnerOppsRes, searchPartnerOpps] = usePromise((searchText: string) => opportunitiesApi.search(searchText))
  const [listOpportunitiesRes, listOpportunities] = usePromise((searchText: string) => opportunitiesApi.getAll(false, searchText))
  const [searchPartnersRes, searchPartners] = usePromise((searchText) => accountsApi.searchPartners(searchText))
  const [searchAllAccountsRes, searchAllAccounts] = usePromise((searchText: string) => accountsApi.searchAllAccounts(searchText))

  const debouncedSearchOpportunities = useCallback(
    debounce((searchText: string) => searchPartnerOpps(searchText), 500),
    [searchPartnerOpps]
  )

  const debouncedSearchPartners = useCallback(
    debounce((searchText: string) => searchPartners(searchText), 500),
    [searchPartners]
  )

  const debouncedSearchAllAccounts = useCallback(
    debounce((searchText: string) => searchAllAccounts(searchText), 500),
    [searchAllAccounts]
  )

  const debouncedSearchCampaigns = useCallback(
    debounce((searchText: string) => searchCampaigns(searchText), 500),
    [searchCampaigns]
  )

  // context calls
  const { opportunityList } = useOpportunityTaskContext()

  // search type options
  const searchTypeOptions = [
    {
      label: "Search from your opportunities",
      description: "Choose an opportunity from a drop down list of all the opportunities where you are the owner",
      value: "myOpportunities",
    },
    {
      label: "Search opportunities by another alias",
      description: "Choose an opportunity from a drop down list of all the opportunities another alias owns.",
      value: "byAlias",
    },
    // {
    //   label: "Search opportunities by partner name",
    //   description: "Choose an opportunity from a drop down list of all the opportunities based on a partner name.",
    //   value: "byPartner",
    // },
    // {
    //   label: "Search opportunities by customer name",
    //   description: "Choose an opportunity from a drop down list of all the opportunities based on the customer name.",
    //   value: "byCustomer",
    // },
    {
      label: "Search all opportunities",
      description: "Choose an opportunity from a drop down list of all opportunities based on a free form text search.",
      value: "freeForm",
    },
    {
      label: "Search campaigns",
      description: "Choose a campaign from a drop down list of all campaigns based on a free form text search.",
      value: "campaigns",
    },
    {
      label: "Search accounts",
      description: "Choose an account from a drop down list of all accounts based on a free form text search.",
      value: "accounts",
    },
  ]

  return (
    <Grid
      gridDefinition={
        ["byAlias", "byPartner", "byCustomer"].includes(searchType) ? [{ colspan: 6 }, { colspan: 6 }, { colspan: 12 }] : [{ colspan: 12 }, { colspan: 12 }]
      }
    >
      <Select
        triggerVariant="option"
        selectedOption={searchTypeOptions.find((item) => item.value === searchType) || null}
        options={searchTypeOptions}
        onChange={(e) => {
          setSearchType(e.detail.selectedOption.value || "")
        }}
      />

      {searchType === "byAlias" && (
        <Box padding={{ top: "xs" }}>
          <Grid gridDefinition={[{ colspan: 10 }, { colspan: 1 }]}>
            <Input
              placeholder="Search alias..."
              value={searchAlias}
              onChange={(e) => {
                setSearchAlias(e.detail.value)
              }}
            />
            <Button
              iconName="refresh"
              formAction="none"
              loading={listOpportunitiesRes.loading}
              onClick={() => {
                listOpportunities(searchAlias)
              }}
            />
          </Grid>
        </Box>
      )}

      {searchType === "byPartner" && (
        <Box padding={searchPartner ? undefined : { top: "xs" }}>
          <Select
            placeholder="Search partner name..."
            filteringType="auto"
            loadingText="Searching partners..."
            statusType={searchPartnersRes.loading ? "loading" : "finished"}
            triggerVariant="option"
            onChange={(e) => {
              setSearchPartner(e.detail.selectedOption.value || "")
              if (e.detail.selectedOption.value) {
                searchPartnerOpps(e.detail.selectedOption.value)
              }
            }}
            onLoadItems={(e) => {
              if (e.detail.filteringText.length > 3) debouncedSearchPartners(e.detail.filteringText)
            }}
            selectedOption={
              searchPartnersRes.data
                ? searchPartnersRes.data
                    .filter((partner) => partner.id === searchPartner)
                    .map((partner) => ({
                      label: partner.name,
                      value: partner.id,
                      description: partner.partnerTier || "",
                      labelTag: partner.spmsId || "",
                      filteringTags: [partner.id || ""],
                    }))[0]
                : null
            }
            options={(searchPartnersRes.data || []).map((item) => ({
              label: item.name,
              value: item.id,
              description: item.partnerTier || "",
              labelTag: item.spmsId || "",
            }))}
          />
        </Box>
      )}

      {searchType === "byCustomer" && (
        <Box padding={searchCustomer ? undefined : { top: "xs" }}>
          <Select
            placeholder="Search customer name..."
            filteringType="auto"
            loadingText="Searching customers..."
            statusType={searchAllAccountsRes.loading ? "loading" : "finished"}
            triggerVariant="option"
            onChange={(e) => {
              setSearchCustomer(e.detail.selectedOption.value || "")
              if (e.detail.selectedOption.value) {
                searchPartnerOpps(e.detail.selectedOption.value)
              }
            }}
            onLoadItems={(e) => {
              if (e.detail.filteringText.length > 3) debouncedSearchAllAccounts(e.detail.filteringText)
            }}
            selectedOption={
              searchAllAccountsRes.data
                ? searchAllAccountsRes.data
                    .filter((account) => account.id === searchCustomer)
                    .map(({ name, id }) => ({
                      label: name,
                      description: id,
                      value: id,
                    }))[0]
                : null
            }
            options={(searchAllAccountsRes.data || []).map(({ name, id }) => ({
              label: name,
              description: id,
              value: id,
            }))}
          />
        </Box>
      )}

      <Select
        filteringType="auto"
        triggerVariant="option"
        empty="No items loaded"
        loadingText="Loading..."
        placeholder={
          listOpportunitiesRes.loading || searchPartnerOppsRes.loading || opportunityList.loading || searchAllAccountsRes.loading ? "Loading items..." : "Select an item..."
        }
        statusType={listOpportunitiesRes.loading || searchPartnerOppsRes.loading || opportunityList.loading || searchAllAccountsRes.loading ? "loading" : "finished"}
        selectedOption={selectedOption || null}
        onChange={(e) => {
          setSelectedOption(e.detail.selectedOption)
          const opp =
            searchType === "myOpportunities"
              ? (opportunityList.data || []).find((item) => item.id === e.detail.selectedOption.value)
              : searchType === "byAlias"
              ? (listOpportunitiesRes.data || []).find((item) => item.id === e.detail.selectedOption.value)
              : searchType === "byPartner" || searchType === "byCustomer" || searchType === "freeForm"
              ? (searchPartnerOppsRes.data || []).find((item) => item.id === e.detail.selectedOption.value)
              : searchType === "campaigns"
              ? (searchCampaignsRes.data || []).find((item) => item.id === e.detail.selectedOption.value)
              : searchType === "accounts"
              ? (searchAllAccountsRes.data || []).find((item) => item.id === e.detail.selectedOption.value)
              : undefined
          if (onSelected) onSelected(searchType === "campaigns" ? "campaign" : searchType === "opportunity" ? "opportunity" : "account", opp)
        }}
        onLoadItems={(e) => {
          if (e.detail.filteringText.length > 3) {
            if (searchType === "freeForm") debouncedSearchOpportunities(e.detail.filteringText)
            if (searchType === "campaigns") debouncedSearchCampaigns(e.detail.filteringText)
            if (searchType === "accounts") debouncedSearchAllAccounts(e.detail.filteringText)
          }
        }}
        options={
          searchType === "myOpportunities"
            ? (opportunityList.data || []).map(({ id, name, accountName, geo, region, fiscalYear, workstreamId, workstreamName }) => ({
                value: id || undefined,
                label: name || undefined,
                labelTag: accountName || undefined,
                description: `${geo}/${region}: ${fiscalYear}`,
                filteringTags: [id || "", accountName || "", geo || "", region || "", ...((workstreamId && [workstreamId]) || [])],
                tags: (workstreamName && [workstreamName]) || undefined,
              }))
            : searchType === "accounts"
            ? (searchAllAccountsRes.data || []).map(({ id, name, spmsId }) => ({
              value: id,
              label: name,
              description: spmsId || "",
            }))
            : searchType === "byAlias"
            ? (listOpportunitiesRes.data || []).map(({ id, name, accountName, geo, region, fiscalYear, workstreamId, workstreamName }) => ({
                value: id || undefined,
                label: name || undefined,
                labelTag: accountName || undefined,
                description: `${geo}/${region}: ${fiscalYear}`,
                filteringTags: [id || "", accountName || "", geo || "", region || "", ...((workstreamId && [workstreamId]) || [])],
                tags: (workstreamName && [workstreamName]) || undefined,
              }))
            : searchType === "byPartner" || searchType === "byCustomer" || searchType === "freeForm"
            ? (searchPartnerOppsRes.data || []).map(({ id, name, recordTypeName }) => ({
                value: id || undefined,
                label: name || undefined,
                // description: `${geo}/${region}: ${fiscalYear}`,
                // filteringTags: [id || "", accountName || "", geo || "", region || "", ...((workstreamId && [workstreamId]) || [])],
                tags: (recordTypeName && [recordTypeName]) || undefined,
              }))
            : searchType === "campaigns"
            ? (searchCampaignsRes.data || []).map(({ id, name }) => ({
                value: id,
                label: name,
              }))
            : []
        }
      />
    </Grid>
  )
}

// ----> [ttsenkov] task to account
// const AccountPicker: React.FC<Props> = ({ onSelected, initialOptions = [] }) => {
//   const [options, setOptions] = useState<SelectProps.Options>(initialOptions)
//   const [status, setStatus] = useState<DropdownStatusProps.StatusType>("pending")
//   const [accountsById, setAccountsById] = useState<Record<string, AccountResponse>>({})

//   const debouncedAutoComplete = useDebounce(accountsApi.search, 200)

//   const handleLoadItems: NonCancelableEventHandler<OptionsLoadItemsDetail> = ({ detail: { filteringText } }) => {
//     if (filteringText && filteringText.length > 2) {
//       const fetch = async () => {
//         try {
//           setStatus("loading")
//           const accounts = await debouncedAutoComplete(filteringText)
//           const options: SelectProps.Option[] = accounts.map((v) => ({
//             value: v.id,
//             label: v.name,
//           }))
//           setAccountsById(keyBy(accounts, "id"))
//           setOptions(options)
//           setStatus("finished")
//         } catch (e) {
//           console.error(e)
//           setStatus("error")
//         }
//       }
//       fetch()
//     }
//   }

//   const handleOnChange: NonCancelableEventHandler<SelectProps.ChangeDetail> = useCallback(
//     (e) => {
//       if (onSelected) {
//         onSelected("account", accountsById[e.detail.selectedOption.value!])
//       }
//     },
//     [accountsById, onSelected]
//   )

//   return (
//     <SelectFormField
//       name="relatedId"
//       required
//       label="Related Account"
//       description="Type then select the account name"
//       filteringType="manual"
//       onLoadItems={handleLoadItems}
//       options={options}
//       onChange={handleOnChange}
//       statusType={status}
//       stretch={true}
//     />
//   )
// }

export default RelatedToPicker
